export const formatCardNumber = (number, brand) => {
  if (!number) return '';
  
  const cleaned = number.replace(/\D/g, '');
  let formatted = '';
  
  // American Express format: XXXX XXXXXX XXXXX
  if (brand === 'amex') {
    formatted = cleaned.match(/.{1,4}|.+/g)?.join(' ') || '';
    return formatted.substring(0, 17); // 4 + 6 + 5 + 2 spaces
  }
  
  // Other cards format: XXXX XXXX XXXX XXXX
  formatted = cleaned.match(/.{1,4}|.+/g)?.join(' ') || '';
  return formatted.substring(0, 19); // 16 digits + 3 spaces
};

export const formatExpiryDate = (input) => {
  const cleaned = input.replace(/\D/g, '');
  if (cleaned.length >= 2) {
    return `${cleaned.slice(0, 2)}/${cleaned.slice(2, 4)}`;
  }
  return cleaned;
};

export const formatDisplayCardNumber = (number) => {
  if (!number) return '•••• •••• •••• ••••';
  const lastFour = number.slice(-4);
  return `•••• •••• •••• ${lastFour}`;
};