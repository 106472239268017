import {
  FETCH_CREDIT_CARD,
  FETCH_CREDIT_CARD_SUCCESS,
  FETCH_CREDIT_CARD_FAILED,
  ADD_CREDIT_CARD,
  ADD_CREDIT_CARD_SUCCESS,
  ADD_CREDIT_CARD_FAILED,
  AUTHORIZE_CREDIT_CARD,
  AUTHORIZE_CREDIT_CARD_SUCCESS,
  AUTHORIZE_CREDIT_CARD_FAILED,
  CAPTURE_CREDIT_CARD,
  CAPTURE_CREDIT_CARD_SUCCESS,
  CAPTURE_CREDIT_CARD_FAILED,
  VOID_CREDIT_CARD,
  VOID_CREDIT_CARD_SUCCESS,
  VOID_CREDIT_CARD_FAILED,
} from "../store/types";

export const INITIAL_STATE = {
  cards: [],
  message: null,
  authorize: null,
  capture: null,
  cancel: null,
};

export const creditcardreducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CREDIT_CARD:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        cards: action.payload,
        loading: false,
      };
    case FETCH_CREDIT_CARD_FAILED:
      return {
        ...state,
        message: action.payload,
        loading: false,
      };
    case ADD_CREDIT_CARD:
      return {
        ...state,
        loading: true,
      };
    case ADD_CREDIT_CARD_SUCCESS:
      return {
        ...state,
      };
    case ADD_CREDIT_CARD_FAILED:
      return {
        ...state,
        message: action.payload,
      };
    case AUTHORIZE_CREDIT_CARD:
      return {
        ...state,
        loading: true,
      };
    case AUTHORIZE_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        authorize: action.payload,
        loading: false,
      };
    case AUTHORIZE_CREDIT_CARD_FAILED:
      return {
        ...state,
        authorize: null,
        loading: false,
        message: action.payload,
      };
    case CAPTURE_CREDIT_CARD:
      return {
        ...state,
        loading: true,
      };
    case CAPTURE_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        capture: action.payload,
        loading: false,
      };
    case CAPTURE_CREDIT_CARD_FAILED:
      return {
        ...state,
        capture: null,
        loading: false,
        message: action.payload,
      };
    case VOID_CREDIT_CARD:
      return {
        ...state,
        loading: true,
      };
    case VOID_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        cancel: action.payload,
        loading: false,
      };
    case VOID_CREDIT_CARD_FAILED:
      return {
        ...state,
        cancel: null,
        loading: false,
        message: action.payload,
      };
    default:
      return state;
  }
};
